import { useEffect, useRef, useState } from 'react';
import Datetime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Check, DeleteForever } from '@material-ui/icons';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';

import { get } from 'helpers/apiHelpers';
import { isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
import { ROLE_SHOW_SUB_BRAND, ROLE_SHOW_ZONE_CATEGORY } from 'helpers/roles';
import getSelectSubBrandLabel from 'helpers/getSelectSubBrandLabel';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import MultiplierCalculator from 'components/MultiplierCalculator/MultiplierCalculator';
import SelectAll from 'components/SelectAll';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  ...buttonsStyle,
  ...extendedFormsStyle,
  card: {
    marginTop: '20px',
  },
  container: {
    position: 'relative',
  },
  deleteIcon: {
    position: 'absolute',
    left: '-10px',
    top: '-10px',
    zIndex: 22,
    background: '#989898',
    color: '#fff',
    borderRadius: '20px',
    padding: 2,
    cursor: 'pointer',
  },
});

const IndividualMultiplierReportRow = props => {
  const {
    rowKey: key,
    onRowRemoved,
    onRowUpdated,
    canBeRemoved,
    useDateRange,
    useSubBrands = true,
    useZoneCategories,
    useRecipeTags,
    showMultiplier,
    brands,
    subBrands,
    zoneCategories,
    recipeTags,
    dateFrom,
    dateTo,
    multiplier,
    includeSubscriptions,
  } = props;

  const { t } = useTranslation();

  const brandsOptions = useSelector(state => state.Auth.user.brands);
  const zoneCategoriesOptions = useSelector(
    state => state.ZoneCategories.zoneCategories
  );
  const recipeTagsOptions = useSelector(
    state => state.Dictionary.productionTags
  );

  const [subBrandsOptions, setSubBrandsOptions] = useState([]);
  const [multiplierCalculatorOpen, setMultiplierCalculatorOpen] =
    useState(false);

  const anchorElement = useRef(null);

  const classes = useStyles();

  const handleChangeMultiplier = ({ target }) => {
    const multiplier = target.value;

    onRowUpdated({ key, multiplier });
  };

  const handleChangeDateTo = event => {
    const dateTo = event.format('YYYY-MM-DD');

    onRowUpdated({ key, dateTo });
  };

  const handleChangeDateFrom = async event => {
    const dateFrom = event.format('YYYY-MM-DD');

    onRowUpdated({ key, dateFrom });
  };

  const handleIncludeSubsToggle = async event => {
    const includeSubscriptions = event.target.checked;

    onRowUpdated({ key, includeSubscriptions });
  };

  const handleBrandChange = selected => {
    const brands = selected;
    const subBrands = [];

    isGranted(ROLE_SHOW_SUB_BRAND) && fetchSubBrands(selected);

    onRowUpdated({ key, brands, subBrands });
  };

  const handleSubBrandChange = selected => {
    const subBrands = selected.filter(el => typeof el != 'undefined');

    onRowUpdated({ key, subBrands });
  };

  const handleZoneCategories = selected => {
    const zoneCategories = selected.filter(el => typeof el != 'undefined');

    onRowUpdated({ key, zoneCategories });
  };

  const handleRecipeTags = selected => {
    const recipeTags = selected.filter(el => typeof el != 'undefined');

    onRowUpdated({ key, recipeTags });
  };

  const fetchSubBrands = async selected => {
    const selectInBrands = selected.length
      ? selected.map(brand => brand.value).filter(val => val !== '*')
      : [];

    const params = {
      pagination: false,
    };

    if (selectInBrands.length > 0) {
      params.selectInBrands = selectInBrands;
    }
    const response = await get('/sub-brands', params);

    const allSubBrandsElement = subBrands.find(
      subBrand => subBrand?.value === '*'
    );

    const subBrandsResponse = response['hydra:member'];
    const subBrandsIds = subBrandsResponse.map(el => el['@id']);

    const selectedSubBrands = allSubBrandsElement
      ? subBrandsResponse.map(({ name: label, '@id': value }) => ({
          label,
          value,
        }))
      : [];

    const prevSelectedSubBrands = subBrands.filter(
      subBrand => subBrand && subBrandsIds.includes(subBrand.value)
    );

    setSubBrandsOptions(subBrandsResponse);
    onRowUpdated({
      key,
      subBrands: [
        allSubBrandsElement,
        ...selectedSubBrands,
        ...prevSelectedSubBrands,
      ],
    });
  };

  useEffect(() => {
    if (isGranted(ROLE_SHOW_SUB_BRAND)) {
      fetchSubBrands(props.brands);
    }
  }, []);

  return (
    <Card className={classes.card}>
      <CardBody>
        <GridContainer justify="flex-start" className={classes.container}>
          {canBeRemoved && (
            <DeleteForever
              onClick={() => onRowRemoved(key)}
              className={classes.deleteIcon}
            />
          )}
          <GridItem sm={showMultiplier ? 2 : 4}>
            <FormLabel className={classes.labelHorizontal}>
              {t('reports.selectBrands')} *
            </FormLabel>
            <SelectAll
              className="input-select--brand"
              options={brandsOptions}
              trackBy="@id"
              mapBy="name"
              optionSelected={brands}
              handleChange={handleBrandChange}
            />
          </GridItem>
          {showMultiplier && (
            <GridItem sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {t('common.multiplier')}
              </FormLabel>
              <FormControl fullWidth>
                <FormTextInput
                  className="input-text--multiplier"
                  classes={classes}
                  name="multiplier"
                  value={multiplier}
                  type="number"
                  handleChange={handleChangeMultiplier}
                  inputSize={12}
                  onFocus={() => {
                    setMultiplierCalculatorOpen(true);
                  }}
                  inputRef={node => {
                    anchorElement.current = node;
                  }}
                />
                <MultiplierCalculator
                  classes={classes}
                  url="/statistics/ecommerce/bags/day"
                  open={multiplierCalculatorOpen}
                  anchorElement={anchorElement.current}
                  brandIds={brands}
                  handleChange={(name, value) => {
                    switch (name) {
                      case 'multiplier':
                        handleChangeMultiplier({ target: { value } });
                        break;
                      case 'multiplierCalculatorOpen':
                        setMultiplierCalculatorOpen(value);
                        break;
                      default:
                        return;
                    }
                  }}
                />
              </FormControl>
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_SUB_BRAND) && useSubBrands && (
            <GridItem sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {t('reports.selectSubbrands')}
              </FormLabel>

              <SelectAll
                className="input-select--subbrand"
                options={subBrandsOptions}
                trackBy="@id"
                mapBy="name"
                optionSelected={subBrands}
                customLabel={subBrand => {
                  return getSelectSubBrandLabel(subBrand, brandsOptions);
                }}
                handleChange={handleSubBrandChange}
              />
            </GridItem>
          )}
          {isGranted(ROLE_SHOW_ZONE_CATEGORY) && useZoneCategories && (
            <GridItem sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {t('reports.selectZoneCategories')}
              </FormLabel>
              <SelectAll
                className="input-select--zone-category"
                options={zoneCategoriesOptions}
                trackBy="@id"
                mapBy="name"
                optionSelected={zoneCategories}
                handleChange={handleZoneCategories}
              />
            </GridItem>
          )}
          {useDateRange && (
            <>
              <GridItem sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('reports.deliveryDateFrom')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    className="input-datetime--date-from"
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(dateFrom)}
                    onChange={handleChangeDateFrom}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('reports.deliveryDateTo')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    className="input-datetime--date-to"
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(dateTo)}
                    onChange={handleChangeDateTo}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
            </>
          )}
          {!useDateRange && (
            <>
              <GridItem sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('reports.selectDate')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    className="input-datetime--date-range"
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(dateFrom)}
                    onChange={handleChangeDateFrom}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
            </>
          )}
          {useRecipeTags && (
            <GridItem sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {t('reports.selectRecipeTags')}
              </FormLabel>
              <SelectAll
                className="input-select--production-tags"
                options={recipeTagsOptions}
                trackBy="@id"
                mapBy="value"
                optionSelected={recipeTags}
                handleChange={handleRecipeTags}
              />
            </GridItem>
          )}
          <GridItem sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  className="input-checkbox--include-subscriptions"
                  name="includeSubscriptions"
                  checked={includeSubscriptions}
                  onChange={handleIncludeSubsToggle}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('reports.includeSubscriptions')}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

IndividualMultiplierReportRow.propTypes = {
  classes: PropTypes.any,
  brands: PropTypes.array,
  subBrands: PropTypes.array,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  multiplier: PropTypes.number,
  includeSubscriptions: PropTypes.bool,
  brandsOptions: PropTypes.array,
  zoneCategoriesOptions: PropTypes.array,
  showMultiplier: PropTypes.bool,
  validateBrands: PropTypes.bool,
  useDateRange: PropTypes.bool,
  useSubBrands: PropTypes.bool,
  useZoneCategories: PropTypes.bool,
  useRecipeTags: PropTypes.bool,
  canBeRemoved: PropTypes.bool,
};

export default IndividualMultiplierReportRow;
