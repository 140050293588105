import React, { useEffect, useState } from 'react';
import roles from 'helpers/roles';
import MIME_TYPES from './constants/mimeTypes';
import { get, post } from 'helpers/apiHelpers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import getReportFileNameWithMultiplier from 'helpers/getReportFileNameWithMultiplier';
import { isGranted } from 'helpers/helpers';
import IndividualMultiplierReport from './IndividualMultiplierReport/IndividualMultiplierReport';

const IndividualMultiplierShoppingList = () => {
  const { user } = useSelector(state => state.Auth);
  const { t } = useTranslation();
  const [fakturowniaWarehouseModule, setFakturowniaWarehouseModule] = useState({
    enabled: false,
  });

  const initShoppingList = async () => {
    const index = user.company.lastIndexOf('/') + 1;
    const companyId = user.company.slice(index, user.company.length);
    if (isGranted(roles.ROLE_EDIT_COMPANY)) {
      const { configuration } = await get(`/company/${companyId}/modules`);

      setFakturowniaWarehouseModule(configuration?.['FakturowniaWarehouse']);
    }
  };

  useEffect(() => {
    initShoppingList();
  }, []);

  const bookInWarehouse = params => {
    return post('/fakturownia/warehouse/reservation', { params });
  };

  return (
    <IndividualMultiplierReport
      name="SHOPPING_LIST"
      title={t('reports.reportShoppingList')}
      url="/reports/shopping"
      mimeTypes={[MIME_TYPES.xlsx]}
      role={roles.ROLE_SHOW_REPORT_SHOPPING}
      reportConfiguration={{
        report: 'Shopping',
        fields: [
          {
            size: 12,
            field: 'reportType',
            inputType: 'select',
            inputProps: {
              multiple: false,
              options: [
                'WEIGHT_SUMMARY',
                'QUANTITY_SUMMARY',
                'ALL_SUMMARY',
              ].map(key => ({
                value: key,
                label: t(`reports.reportShoppingList.reportType.${key}`),
              })),
            },
          },
          {
            size: 12,
            field: 'roundDecimalPoints',
            inputType: 'number',
          },
        ],
      }}
      fileName={params =>
        getReportFileNameWithMultiplier(
          params,
          t('common.mainMenu.reportsShoppingList')
        )
      }
      useDateRange={true}
      useZoneCategories={true}
      showMultiplier={true}
      shouldValidateBrands={false}
      validateDatePicker={true}
      usePost={true}
      useRecipeTags={true}
      singleSelectRecipeTags={true}
      useWarehouse={fakturowniaWarehouseModule.enabled}
      singleSelectWarehouse={fakturowniaWarehouseModule.enabled}
      customActions={
        fakturowniaWarehouseModule.enabled
          ? [
              {
                onClick: bookInWarehouse,
                label: t('$*reports.shoppingList.bookInWarehose'),
              },
            ]
          : []
      }
      archiveReportNames={['SHOPPING_LIST']}
      isSalesMarketLangViewEnabled={true}
    />
  );
};

export default IndividualMultiplierShoppingList;
