import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'helpers/gastro';
import Cookies from 'cookies-js';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import roles from 'helpers/roles';
import { isGranted } from 'helpers/helpers';

import { fetchZoneCategories } from 'actions/ZoneCategories';
import { fetchProductionTags } from 'actions/Dictionary';
import { fetchSubBrands } from 'actions/SubBrands';

//components
import { SelectInput } from 'components';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import Archive from 'components/Archive/Archive';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import SelectAll from 'components/SelectAll';
import GridContainer from 'components/Grid/GridContainer';
import AccessDenied from 'components/Security/AccessDenied';
import ReportConfigurator from 'components/Report/ReportConfigurator';

import { AddCircle } from '@material-ui/icons';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

//styles
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import CombinedReportRow from './IndividualMultiplierReportRow';
import Info from '@material-ui/icons/Info';
import CardWrapper from 'components/Card/CardWrapper';
import DownloadReportButtons from '../DownloadReportButtons/DownloadReportButtons';
import MIME_TYPES_DATA from '../constants/mimeTypesData';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/styles';
import produce from 'immer';
import classNames from 'classnames';

const defaultRow = () => ({
  key: uuidv4(),
  brands: [],
  multiplier: 1,
  subBrands: [],
  dateFrom: '',
  dateTo: '',
  zoneCategories: [],
  includeSubscriptions: false,
  recipeTags: [],
});

const useStyles = makeStyles({
  ...buttonsStyle,
  ...extendedFormsStyle,
  cardWrapper: {
    marginTop: '20px',
  },
  dialogContent: {
    textAlign: 'center',
  },
  formLabel: {
    marginBottom: '2px',
  },
  tooltip: {
    color: 'grey',
    marginLeft: '5px',
    marginBottom: '-4px',
  },
  submitButtons: {
    display: 'flex',
  },
});

const IndividualMultiplierReport = ({
  name,
  title,
  url,
  mimeTypes,
  role,
  reportConfiguration,
  fileName,
  useDateRange,
  useZoneCategories,
  showMultiplier,
  shouldValidateBrands,
  validateDatePicker,
  usePost,
  useRecipeTags,
  singleSelectRecipeTags,
  useWarehouse,
  singleSelectWarehouse,
  customActions,
  archiveReportNames,
  isSalesMarketLangViewEnabled = false,
  additionalParams,
  labelSelectTags,
  children,
  useSubBrands,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const brandId = useSelector(state => state.Brands.brand.id);
  const { brands, company, zoneCategories } = useSelector(state => ({
    ...state.Auth.user,
    ...state.ZoneCategories,
  }));
  const subBrandsOptions = useSelector(state => state.SubBrands.subBrands);
  const recipeTagsOptions = useSelector(
    state => state.Dictionary.productionTags
  );

  const [isGenerating, setIsGenerating] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [parameters, setParameters] = useState([defaultRow()]);
  const [recipeTags, setRecipeTags] = useState([]);
  const [warehouse, setWarehouse] = useState('');
  const [availableWarhouses, setAvailableWarhouses] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const brandsIris = brands.map(brand => brand['@id']);
      if (useZoneCategories && isGranted(roles.ROLE_SHOW_ZONE_CATEGORY)) {
        dispatch(fetchZoneCategories(brandsIris));
      }
      if (useRecipeTags) {
        dispatch(fetchProductionTags());
      }
      if (useWarehouse) {
        try {
          let newAvailableWarhouses = await axios.get(
            `/fakturownia/warehouses`
          );

          if (Array.isArray(newAvailableWarhouses?.data)) {
            setWarehouse(
              !isEmpty(newAvailableWarhouses?.data)
                ? newAvailableWarhouses?.data[0]
                : null
            );
            setAvailableWarhouses(newAvailableWarhouses?.data);
          }
        } catch (e) {
          console.log(e);
        }
      }

      dispatch(fetchSubBrands(brandsIris));

      const cookieState = Cookies.get('individual_multipliers_report');

      if (cookieState) {
        const { parameters, recipeTags, warehouse, availableWarhouses } =
          JSON.parse(cookieState);

        setParameters(parameters);
        setRecipeTags(recipeTags);
        setWarehouse(warehouse);
        setAvailableWarhouses(availableWarhouses);
      }

      setIsInitialized(true);
    })();
  }, []);

  const addNextRow = () => {
    setParameters(prevState => [...prevState, defaultRow()]);
  };

  const onRowRemoved = key => {
    setParameters(prevState =>
      prevState.filter(parameter => parameter.key !== key)
    );
  };

  const onRowUpdated = row => {
    const rowIndex = parameters.findIndex(
      parameter => parameter.key === row.key
    );

    setParameters(
      produce(draft => {
        draft[rowIndex] = { ...draft[rowIndex], ...row };
      })
    );
  };

  const validateDate = row => {
    if (validateDatePicker) {
      return useDateRange ? row.dateFrom && row.dateTo : row.dateFrom;
    }

    return true;
  };

  const validateBrands = row => {
    if (shouldValidateBrands) {
      return row.brands.length !== 0;
    }

    return true;
  };

  const validate = row => {
    if (!validateDate(row)) {
      toast.error(t('reports.sDate'));
      return false;
    }
    if (!validateBrands(row)) {
      toast.error(t('reports.selectBrand'));
      return false;
    }

    return true;
  };

  const handleWarehouse = (ev, selectedValue) => {
    setWarehouse(selectedValue);
  };

  const handleRecipeTags = async selected => {
    setRecipeTags(selected.filter(el => typeof el != 'undefined'));
  };

  const filterRecipeTags = recipeTags => {
    return recipeTags.filter(val => val).map(val => val.value);
  };

  const handleGenerate = (mimeType, lang) => {
    if (parameters.some(parameter => !validate(parameter))) {
      return;
    }

    Cookies.set(
      'individual_multipliers_report',
      JSON.stringify({
        parameters,
        recipeTags,
        warehouse,
        availableWarhouses,
      })
    );
    setIsGenerating(true);

    const params = getParams();

    const actionsConfig = {
      responseType: 'blob',
      headers: { accept: mimeType },
      forcedLanguage: lang,
    };

    const action = usePost
      ? axios.post(url, params, actionsConfig)
      : axios.get(url, {
          ...actionsConfig,
          params,
        });

    action
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${fileName({ parameters })}.${
                MIME_TYPES_DATA[mimeType].extension
              }`
            );
            document.body.appendChild(link);
            link.click();
            setIsGenerating(false);
          } else {
            alert(t('reports.noAccess'));
          }
        },
        error => {
          if (typeof error.response !== 'undefined') {
            toast.error(
              `${t('reports.cannotGenerate')} ${
                error.response.data['hydra:description']
              }`
            );
          } else {
            toast.error(`${t('reports.cannotGenerate')} ${error}`);
          }
          setIsGenerating(false);
        }
      )
      .finally(() => {
        setIsGenerating(false);
      });
  };

  const getParams = () => {
    const mappedParameters = parameters.map(parameter => {
      let recipeTags = [];

      if (useRecipeTags) {
        recipeTags = filterRecipeTags(
          singleSelectRecipeTags ? recipeTags : parameter.recipeTags
        );
      }

      let mappedParameter = {
        ...parameter,
        brands: parameter.brands.filter(val => val).map(val => val.value),
        subBrands: parameter.subBrands.filter(val => val).map(val => val.value),
        ...(useZoneCategories && {
          zoneCategories: parameter.zoneCategories
            .filter(val => val)
            .map(val => val.value),
        }),
        recipeTags: recipeTags,
      };

      return mappedParameter;
    });

    let resultAdditionalParams = {};

    if (typeof additionalParams === 'object') {
      resultAdditionalParams = additionalParams;
    }

    if (useRecipeTags && singleSelectRecipeTags) {
      resultAdditionalParams.recipeTags = filterRecipeTags(recipeTags);
    }

    if (useWarehouse && singleSelectWarehouse) {
      resultAdditionalParams.warehouse = warehouse;
    }

    const params = {
      parameters: mappedParameters,
      ...resultAdditionalParams,
    };
    return params;
  };

  const companyId = parseInt(company.split('/').pop());
  const reportKey = reportConfiguration?.report || false;
  const fields = reportConfiguration?.fields;

  if (!isInitialized) {
    return null;
  }

  return isGranted(role) ? (
    <>
      <CardWrapper
        title={
          <>
            {title}
            {reportKey && (
              <ReportConfigurator
                companyId={companyId}
                brandId={brandId}
                report={reportKey}
                fields={fields}
              />
            )}
          </>
        }
        className={classes.cardWrapper}
      >
        <Dialog open={isGenerating}>
          <DialogContent>
            <div className={classes.dialogContent}>
              <h1>{t('reports.generate')}</h1>
              <CircularProgress />
            </div>
          </DialogContent>
        </Dialog>
        <CardBody>
          {parameters.map((el, index) => (
            <CombinedReportRow
              key={el.key}
              rowKey={el.key}
              onRowRemoved={onRowRemoved}
              onRowUpdated={onRowUpdated}
              canBeRemoved={index !== 0}
              brands={el.brands}
              subBrands={el.subBrands}
              zoneCategories={el.zoneCategories}
              recipeTags={el.recipeTags}
              dateFrom={el.dateFrom}
              dateTo={el.dateTo}
              multiplier={el.multiplier}
              includeSubscriptions={el.includeSubscriptions}
              useDateRange={useDateRange}
              useSubBrands={useSubBrands}
              useZoneCategories={useZoneCategories}
              useRecipeTags={useRecipeTags && !singleSelectRecipeTags}
              showMultiplier={showMultiplier}
            />
          ))}
          <GridContainer justify="flex-end">
            {singleSelectWarehouse && (
              <GridItem sm={2}>
                <FormLabel
                  className={classNames(
                    classes.labelHorizontal,
                    classes.formLabel
                  )}
                >
                  {t('reports.selectWarehouse')}
                </FormLabel>
                <SelectInput
                  noGrid={true}
                  classes={classes}
                  mapBy="name"
                  trackBy="id"
                  name="warehouse"
                  value={warehouse}
                  options={availableWarhouses}
                  handleChange={handleWarehouse}
                />
              </GridItem>
            )}
            {singleSelectRecipeTags && (
              <GridItem sm={2}>
                <FormLabel
                  className={classNames(
                    classes.labelHorizontal,
                    classes.formLabel
                  )}
                >
                  {labelSelectTags
                    ? labelSelectTags
                    : t('reports.selectRecipeTags')}
                </FormLabel>
                <Tooltip
                  className={classes.tooltip}
                  title={
                    <h4>
                      {[
                        'SHOPPING_LIST',
                        'CHECK_LIST_RECIPES',
                        'RECIPES_CARD',
                      ].some(el => el === name)
                        ? t('reports.selectTags.recipe.tooltip')
                        : ['CHECK_LIST_DISHES', 'DISHES_CARD'].some(
                            el => el === name
                          )
                        ? t('reports.selectTags.dish.tooltip')
                        : null}
                    </h4>
                  }
                  placement="right"
                >
                  <Info fontSize="small" />
                </Tooltip>
                <SelectAll
                  className="input-select--production-tags"
                  options={recipeTagsOptions}
                  trackBy="@id"
                  mapBy="value"
                  optionSelected={recipeTags}
                  handleChange={handleRecipeTags}
                />
              </GridItem>
            )}
          </GridContainer>

          {children}
          <GridContainer justify="space-between">
            <GridItem>
              <Button
                onClick={addNextRow}
                disabled={isGenerating}
                color="default"
                round={true}
                justIcon={true}
              >
                <AddCircle />
              </Button>
            </GridItem>
            <div className={classes.submitButtons}>
              {(customActions ?? []).map(({ onClick, label }) => (
                <GridItem key={label}>
                  <Button
                    onClick={async () => {
                      setIsGenerating(true);
                      try {
                        const params = this.getParams();
                        await onClick(params);
                        setIsGenerating(false);
                      } catch (e) {
                        setIsGenerating(false);
                      }
                    }}
                    disabled={isGenerating}
                    color="success"
                    round={true}
                  >
                    {label}
                  </Button>
                </GridItem>
              ))}
              <GridItem>
                <DownloadReportButtons
                  isSalesMarketLangViewEnabled={isSalesMarketLangViewEnabled}
                  mimeTypes={mimeTypes}
                  handleGenerate={handleGenerate}
                  areButtonsDisabled={isGenerating}
                />
              </GridItem>
            </div>
          </GridContainer>
        </CardBody>
      </CardWrapper>
      <Card>
        <CardBody>
          <Archive
            options={{
              brands: brands,
              subBrands: subBrandsOptions,
              zoneCategories: zoneCategories,
              recipeTags: recipeTagsOptions,
            }}
            type="REPORT"
            reportName={archiveReportNames}
          />
        </CardBody>
      </Card>
    </>
  ) : (
    <AccessDenied />
  );
};

IndividualMultiplierReport.propTypes = {
  brands: PropTypes.array,
  zoneCategories: PropTypes.array,
  classes: PropTypes.any,
  archiveReportNames: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.func.isRequired,
  showMultiplier: PropTypes.bool,
  validateBrands: PropTypes.bool,
  validateDatePicker: PropTypes.bool,
  usePost: PropTypes.bool,
  useDateRange: PropTypes.bool,
  useSubBrands: PropTypes.bool,
  useZoneCategories: PropTypes.bool,
  useRecipeTags: PropTypes.bool,
  mimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  isSalesMarketLangViewEnabled: PropTypes.bool,
};

export default IndividualMultiplierReport;
